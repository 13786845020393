import {fetchDefault, fetchJson, uploadFile} from "./fetch";
import {getUrl, isMock} from "./getUrl";
import {Exhibition, Exhibitions, Pagination} from "./types";

let mocks = null;

if (isMock) {
    mocks = import(/* webpackChunkName: 'mockService' */ "./mocks/index");
}

export const getExhibitions = (params: Pagination): Promise<Exhibitions> => mocks ?
    mocks.then(module => module.exhibitions()) :
    fetchJson({
        uri: getUrl('api/exhibitions'),
        method: 'post',
        params
    });

export const addExhibition = (files: FileList): Promise<Exhibition> => mocks ?
    mocks.then(module => module.exhibitions()) :
    uploadFile({
        uri: getUrl('api/admin/exhibition'),
        method: 'post'
    }, files[0]);

export const saveExhibition = (params: Exhibition): Promise<boolean> => mocks ?
    mocks.then(module => module.exhibitions()) :
    fetchJson({
        uri: getUrl('api/admin/exhibition'),
        method: 'put',
        params
    }).then(() => true);

export const removeExhibition = (id: number): Promise<boolean> => mocks ?
    mocks.then(module => module.exhibitions()) :
    fetchJson({
        uri: getUrl(`api/admin/exhibition/${id}`),
        method: 'delete'
    }).then(() => true);

export const swapPriorities = (params: {
    swapExhibitionIdFrom: number,
    swapExhibitionIdTo: number
}): Promise<boolean> => mocks ?
    mocks.then(module => module.exhibitions()) :
    fetchDefault({
        uri: getUrl('api/admin/exhibition/priorities'),
        method: 'post',
        params
    }, {
        "Content-Type": "application/json"
    }).then(() => true);


export const uploadInvitation = (id: number, files: FileList): Promise<boolean> => mocks ?
    mocks.then(module => true) :
    uploadFile({
        uri: getUrl(`api/admin/exhibition/${id}`),
        method: 'put'
    }, files[0]).then(() => true);