import * as React from 'react';
import {ChangeEvent} from 'react';
import css from './NewsItem.css';
import {UploadField} from '@navjobs/upload'

type Props = {
    name: string;
    exhibitionType: string;
    address: string;
    timeAndPlace: string;
    features: string;
    isAdmin: boolean;
    invitation: string;
    id: number;
    isLast: boolean;
    isFirst: boolean;
    onChange: (id: number, type: string, content: string) => void;
    save: (id: number) => void;
    onChangeInvitation: (id: number, files: FileList) => void;
    remove: (id: number) => void;
    moveUp: (id: number) => void;
    moveDown: (id: number) => void;
};

export class NewsItem extends React.Component<Props, null> {

    save = () => {
        this.props.save(this.props.id);
    };
    remove = () => {
        this.props.remove(this.props.id);
    };
    moveUp = () => {
        this.props.moveUp(this.props.id);
    };
    moveDown = () => {
        this.props.moveDown(this.props.id);
    };


    onChange = (type: string, event: ChangeEvent<HTMLInputElement>) => {
        this.props.onChange(this.props.id, type, event.currentTarget.value);
    };

    onChangeInvitation = (files: FileList) => {
        this.props.onChangeInvitation(this.props.id, files);
    };
    onChangeExhibitionType = this.onChange.bind(this, 'exhibitionType');
    onChangeName = this.onChange.bind(this, 'name');
    onChangeAddress = this.onChange.bind(this, 'address');
    onChangeFeatures = this.onChange.bind(this, 'features');
    onChangeTimeAndPlace = this.onChange.bind(this, 'timeAndPlace');

    render() {
        const {exhibitionType, address, timeAndPlace, features, name, isAdmin, invitation, isFirst, isLast} = this.props;
        return (
            <div className={css.container}>
                {
                    isAdmin ? (<div className={css.adminButtons}>
                        <div onClick={this.remove}>☓</div>
                        {isFirst ? null : (<div onClick={this.moveUp}>▲</div>)}
                        {isLast ? null : (<div onClick={this.moveDown}>▼</div>)}
                    </div>) : null
                }
                <textarea
                    className={css.header}
                    placeholder='Exhibition type'
                    readOnly={!isAdmin}
                    onChange={isAdmin && this.onChangeExhibitionType}
                    value={exhibitionType} />
                <div className={css.break} />
                <textarea
                    className={css.name}
                    placeholder='name'
                    readOnly={!isAdmin}
                    onChange={isAdmin && this.onChangeName}
                    value={name} />
                <textarea
                    className={css.address}
                    placeholder='address'
                    readOnly={!isAdmin}
                    onChange={isAdmin && this.onChangeAddress}
                    value={address} />
                <textarea
                    className={css.features}
                    placeholder='features'
                    readOnly={!isAdmin}
                    onChange={isAdmin && this.onChangeFeatures}
                    value={features} />
                <textarea
                    className={css.timeAndPlace}
                    placeholder='timeAndPlace'
                    readOnly={!isAdmin}
                    onChange={isAdmin && isAdmin && this.onChangeTimeAndPlace}
                    value={timeAndPlace} />
                {isAdmin ? [
                        <UploadField
                            key="invitation"
                            onFiles={this.onChangeInvitation}
                            containerProps={{
                                className: css.invitation
                            }}
                            uploadProps={{
                                accept: '.pdf',
                            }}>
                            <div>Change PDF</div>
                        </UploadField>,
                        <div className={css.invitation} onClick={this.save} key="save">
                            Save
                        </div>
                    ] :
                    <a href={invitation} target='_blank' className={css.invitation}>PDF</a>
                }
            </div>
        );
    }
}
