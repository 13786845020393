import * as React from 'react';
import {render} from 'react-dom';
import './index.css';

let root;

function init() {
    // HMR requires that this be a require()
    let App = require<any>('./App/App').App;
    // render the app and save the new root element:
    root = render(<App />, document.body, root);
}


// initial render!
init();

// If this is webpack-dev-server, set up HMR :)
if (module.hot) module.hot.accept('./App/App', init);