import {fetchJson} from "./fetch";
import {getUrl, isMock} from "./getUrl";
import {ISubscribers, Pagination} from "./types";

let mocks = null;

if (isMock) {
    mocks = import(/* webpackChunkName: 'mockService' */ "./mocks/index");
}

export const subscribe = (params: { name: string, surname: string, email: string }): Promise<boolean> => mocks ?
    mocks.then(module => module.exhibitions()) :
    fetchJson({
        uri: getUrl('api/subscribe'),
        method: 'post',
        params
    }).then(() => true);

export const listSubscribers = (params: Pagination = {
    skip: 0,
    limit: 0,
    direction: 'ASC',
    property: 'email'
}): Promise<ISubscribers> => mocks ?
    mocks.then(module => module.exhibitions()) :
    fetchJson({
        uri: getUrl('api/admin/subscribers/emails'),
        method: 'post',
        params
    });

export const confirmSubscriber = (subscriberId: number): Promise<boolean> => mocks ?
    mocks.then(module => module.exhibitions()) :
    fetchJson({
        uri: getUrl(`api/admin/confirm/subscriber/email/${subscriberId}`),
        method: 'put'
    }).then(() => true);