type Options = {
    uri: string,
    method: "get" | "post" | "put" | "delete",
    params?: Object
};

const checkStatus = response =>
    response.status >= 200 && response.status < 300
        ? response ? response.json() : ''
        : Promise.reject(Error(response.statusText));

export const fetchJson = (options: Options) =>
    window
        .fetch(options.uri, {
            method: options.method,
            headers: {
                "Content-Type": "application/json"
            },
            credentials: 'same-origin',
            body: options.params ? JSON.stringify(options.params) : null
        })
        .then(checkStatus);


const checkStatusDefault = response =>
    response.status >= 200 && response.status < 300
        ? response
        : Promise.reject(Error(response.statusText));

export const fetchDefault = (options: Options, headers?) =>
    window
        .fetch(options.uri, {
            method: options.method,
            headers,
            body: options.params ? JSON.stringify(options.params) : null,
            credentials: 'same-origin'
        })
        .then(checkStatusDefault);

export const uploadFile = (options: Options, file: Blob) => {
    const fd = new FormData();
    fd.append('file', file);
    return window
        .fetch(options.uri, {
            method: options.method,
            body: fd,
            credentials: 'same-origin'
        })
        .then(checkStatus);
};
