import * as React from 'react';
import {ChangeEvent} from 'react';
import css from './Contact.css';
import {confirmSubscriber, listSubscribers, subscribe} from "../api/subscribe";
import {ISubscriber} from "../api/types";

type Props = {
    isAdmin: boolean;
};

interface State {
    name: string;
    surname: string;
    email: string;
    nameInvalid: boolean;
    surnameInvalid: boolean;
    emailInvalid: boolean;
    subscribers: ISubscriber[];
}

const emailReg = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

export class Contact extends React.Component<Props, State> {
    instagram = 'https://www.instagram.com/olga_golos_';
    vimeo = 'https://vimeo.com/olgagolos';
    mail = 'info@olgagolos.com';
    state = {
        name: '',
        surname: '',
        email: '',
        nameInvalid: false,
        surnameInvalid: false,
        emailInvalid: false,
        subscribers: []
    };

    componentDidMount() {
        this.listSubscribers();
    }

    listSubscribers = () => {
        if (this.props.isAdmin) {
            listSubscribers().then((subscribers) => {
                this.setState({
                    subscribers: subscribers.content
                })
            })
        }
    };

    onChangeName = (e: ChangeEvent<HTMLInputElement>) => {
        this.setState({
            name: e.target.value,
            nameInvalid: false
        })
    };

    onChangeSurname = (e: ChangeEvent<HTMLInputElement>) => {
        this.setState({
            surname: e.target.value,
            surnameInvalid: false
        })
    };

    onChangeEmail = (e: ChangeEvent<HTMLInputElement>) => {
        this.setState({
            email: e.target.value,
            emailInvalid: false
        })
    };

    onSubscribe = () => {
        const {email, name, surname} = this.state;
        if (!email || !name || !surname) {
            this.setState({
                emailInvalid: !email,
                nameInvalid: !name,
                surnameInvalid: !surname
            });
        } else if (!email.match(emailReg)) {
            this.setState({
                emailInvalid: true,
            });
        } else {
            subscribe({name, email, surname}).then(() => {
                this.setState({
                    name: '',
                    surname: '',
                    email: '',
                    nameInvalid: false,
                    surnameInvalid: false,
                    emailInvalid: false,
                })
            });
        }
    };

    confirm = (id: number) => {
        confirmSubscriber(id).then(this.listSubscribers)
    };

    render() {
        const {isAdmin} = this.props;
        const {emailInvalid, nameInvalid, surnameInvalid, subscribers} = this.state;
        return (
            <div className={css.container}>
                <div className={css.header}>
                    Contact
                </div>
                <div className={css.break} />
                <a className={css.email} href={`mailto:${this.mail}`}>{this.mail}</a>
                <div className={css.buttons}>
                    <a className={css.button} href={this.vimeo} target='_blank'>
                        <svg viewBox="0 0 76 76" xmlns="http://www.w3.org/2000/svg">
                            <path fill='white'
                                  d="M71.9 9.9c-1.8-1.9-4.4-3-7.7-3.1-9.4-.3-16 4.7-19.7 14.8-.2.5-.1 1.1.3 1.5.4.4 1 .6 1.5.4 1.1-.3 2.1-.4 3.1-.4 1.4 0 3.2.3 4.1 1.4.7.9.8 2.3.3 4.1-.4 1.5-2.6 5.6-5.4 9.9-3.3 5.2-5.2 7.6-5.7 7.6-1.8 0-8.1-28.2-8.3-28.8-1.8-6.5-2.7-9.7-8.7-9.7C21.3 7.7 11.2 16 3 23.2c-.6.4-1 .8-1.3 1.1-.8.7-.9 1.8-.3 2.7L3 29c.6.8 1.8 1 2.7.4l.1-.1c2.7-1.9 5.2-3.7 7.1-3.8 2.1-.1 3.9 3.1 6 10.8 4.1 15 10.3 32.8 16.7 32.8 6.7 0 14.6-5.6 24.9-19 9.2-12 14.1-21.6 14.5-28.6.2-5.3-.8-9.2-3.1-11.6z" />
                        </svg>
                    </a>
                    <a className={css.button} href={this.instagram} target='_blank'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 510 510">
                            <path fill='white'
                                  d="M459 0H51C22.95 0 0 22.95 0 51v408c0 28.05 22.95 51 51 51h408c28.05 0 51-22.95 51-51V51c0-28.05-22.95-51-51-51zM255 153c56.1 0 102 45.9 102 102s-45.9 102-102 102-102-45.9-102-102 45.9-102 102-102zM63.75 459C56.1 459 51 453.9 51 446.25V229.5h53.55C102 237.15 102 247.35 102 255c0 84.15 68.85 153 153 153s153-68.85 153-153c0-7.65 0-17.85-2.55-25.5H459v216.75c0 7.65-5.1 12.75-12.75 12.75H63.75zM459 114.75c0 7.65-5.1 12.75-12.75 12.75h-51c-7.65 0-12.75-5.1-12.75-12.75v-51c0-7.65 5.1-12.75 12.75-12.75h51C453.9 51 459 56.1 459 63.75v51z" />
                        </svg>
                    </a>
                </div>
                <div className={css.name}>
                    Newsletter
                </div>
                <input className={`${css.input} ${nameInvalid && css.invalid}`} placeholder='Name'
                       onChange={this.onChangeName} />
                <input className={`${css.input} ${surnameInvalid && css.invalid}`} placeholder='Last Name'
                       onChange={this.onChangeSurname} />
                <input className={`${css.input} ${emailInvalid && css.invalid}`} type='email' placeholder='Email'
                       onChange={this.onChangeEmail} />
                <div className={css.subscribe} onClick={this.onSubscribe}>Subscribe</div>
                {isAdmin && subscribers.length && (
                    <div className={css.subscribers}>
                        <div>Subscribers:</div>
                        {
                            subscribers.map((subscriber) => (
                                <div className={css.subscriber}>
                                    <div>{`${subscriber.name} ${subscriber.surname} ${subscriber.email}`}</div>
                                    {subscriber.confirmed ?
                                        (<div className={css.confirmed}>Confirmed</div>) :
                                        (<div className={css.confirm}
                                              onClick={() => this.confirm(subscriber.id)}
                                        >Confirm</div>)
                                    }
                                </div>
                            ))
                        }
                    </div>)}
            </div>
        );
    }
}
