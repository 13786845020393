import {uploadFile} from "./fetch";
import {getUrl, isMock} from "./getUrl";

let mocks = null;

if (isMock) {
    mocks = import(/* webpackChunkName: 'mockService' */ "./mocks/index");
}

export const uploadPortfolio = (files: FileList): Promise<boolean> => mocks ?
    mocks.then(module => true) :
    uploadFile({
        uri: getUrl('api/admin/upload/portfolios'),
        method: 'post'
    }, files[0]).then(() => true);

export const uploadCV = (files: FileList): Promise<boolean> => mocks ?
    mocks.then(module => true) :
    uploadFile({
        uri: getUrl('api/admin/upload/resume'),
        method: 'post'
    }, files[0]).then(() => true);

export const uploadImage = (files: FileList, imageType: 'GLOBAL' | 'EXHIBITION'): Promise<boolean> => mocks ?
    mocks.then(module => true) :
    uploadFile({
        uri: getUrl(`api/admin/upload/background/image/${imageType}`),
        method: 'post'
    }, files[0]).then(() => true);
