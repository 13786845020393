import * as React from 'react';
import {CSSProperties} from "react";
import css from './Box.css';

type Props = {
    style?: CSSProperties;
};

export class Box extends React.Component<Props, null> {
    render() {
        return (
            <div className={css.container} style={this.props.style}>
                {this.props.children}
            </div>
        );
    }
}
